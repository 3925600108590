function findAncestor(el, cls) {
  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
}

var onEndEvents = (function() {
  var el = document.createElement('LT');

  return function(eventNames) {
    for (var name in eventNames) {
      if (el.style[name] !== undefined) {
        return eventNames[name];
      }
    }

    return false;
  };
})();

LT.utils = {
  toggleElement: function(selector, cb) {
    var elements = document.querySelectorAll(selector);

    for (var i = elements.length - 1; i >= 0; i--) {
      var toggle = elements[i];

      cb(toggle);
    }
  },
  collapse: function(el) {
    function toggleHandler(toggle) {
      toggle.addEventListener('click', function(e) {
        e.preventDefault();
        var collapse = findAncestor(this, 'collapse');

        if (collapse.classList.contains('is-open')) {
          collapse.classList.remove('is-open');
        } else {
          collapse.classList.add('is-open');
        }
      });
    }

    this.toggleElement(el, toggleHandler);
  },
  revealImages: function() {
    var catalogItems = document.querySelectorAll('.js-catalog-item .js-animation');
    var self = this;

    for (var i = 0, l = catalogItems.length; i < l; i++) {
      self.addHoverToImages(catalogItems[i]);
    }
  },
  addHoverToImages: function(item) {
    var itemW = item.offsetWidth;
    var itemOffsetLeft = item.offsetLeft;
    var canAnimate = item.offsetWidth < window.innerWidth;

    if (!canAnimate) {
      return false;
    }

    var isAnimating = false;

    item.addEventListener('mouseenter', function(e) {
      if (!isAnimating) {
        item.classList.add('animate');
        isAnimating = true;
      }
    });

    item.addEventListener('mouseout', function(e) {
      item.classList.remove('animate');
    });

    item.addEventListener(LT.utils.transEndEventName, function(event) {
      isAnimating = false;
    });
  },
};

// Animation callback
var animEndEventNames = {
  WebkitAnimation: 'webkitAnimationEnd',
  MozAnimation: 'animationend',
  animation: 'animationend',
};
LT.utils.animEndEventName = onEndEvents(animEndEventNames);

// Transition callback
var transEndEventNames = {
  WebkitTransition: 'webkitTransitionEnd',
  MozTransition: 'transitionend',
  OTransition: 'oTransitionEnd otransitionend',
  transition: 'transitionend',
};
LT.utils.transEndEventName = onEndEvents(transEndEventNames);
